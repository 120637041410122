import {Component, OnInit} from '@angular/core';
import {ConstantHelper} from '../../_helpers/constant.helper';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Message} from '../../_locale/message';
import {CommonHelper} from '../../_helpers/common.helper';
import {AppConfig} from '../../app.config';
import {ActivatedRoute, Router} from '@angular/router';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {DomSanitizer} from '@angular/platform-browser';
import {UserService} from '../../_services/user.service';
import {ApiHelper} from '../../_helpers/api.helper';

@Component({
  selector: 'app-admin-invitation-log',
  templateUrl: './admin-invitation-log.component.html',
  styleUrls: ['./admin-invitation-log.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class AdminInvitationLogComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  loggedInUserId: string;
  resultJsonObj: any = {};
  usersList: any = [];
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  loggedInUserRoleId: any;
  userRoleVal: any = 0;
  emailLogTxt: any = '';
  toEmailUserId: any;
  showLoader = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  showFirstAdminMessageModal = false;
  loggedInUserName: string;
  loggedInUserEmail: string;

  constructor(
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  }

  ngOnInit() {
    this.dtOptions = {
      order : []
    };
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.findAdminInvitationLogs();
    this.loggedInUserName = localStorage.getItem("loggedInUserName") ? localStorage.getItem("loggedInUserName") : "Admin";
    this.loggedInUserEmail = localStorage.getItem("loggedInUserEmail") ? localStorage.getItem("loggedInUserEmail") : "";
  }


  async findAdminInvitationLogs() {
    this.usersList = [];
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      userRoleVal: this.userRoleVal,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getAdminInvitationLogsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
        },
        (err) => {
          console.error(err);
        }
      );
  }
  filterPhysician(userRoleVal) {
    this.userRoleVal = userRoleVal;
    this.findAdminInvitationLogs();
  }
  resetSearch() {
    this.userRoleVal = 0;
    this.findAdminInvitationLogs();
  }











  async onReset() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.adminChangePassComp.onReset);
    // if (alertStatus) {
      this.router.navigate(['/' + this.childRoute + '/dashboard/' + this.loggedInUserId]);
    // }
  }
  async goToAdministrators() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.adminChangePassComp.goToAdmin);
    // if (alertStatus) {
      this.router.navigate(['/' + this.childRoute + '/users/' + this.loggedInUserId]);
    // }
  }

  async resendInvite(invitationId, fullName, email, mobile, userRole) {
    if(userRole == "Physician"){
      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        fullName: fullName,
        mobile: mobile,
        email: email.toLowerCase(),
        loggedInUserId: this.loggedInUserId,
        loggedInUserName: this.loggedInUserName,
        senderEmail: this.loggedInUserEmail
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl04() + ApiHelper.name_invitePhysicainApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.showLoader = false;
            await this.sweetAlertHelper.alertPopUp(apiResponse.commandResult.message);
            window.location.reload();
          },
          (err) => {
            console.error(err);
          }
        );
    } else if (userRole == "Administrative"){
      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        fullName: fullName,
        mobile: mobile,
        email: email,
        loggedInUserId: this.loggedInUserId,
        loggedInUserName: this.loggedInUserName,
        inviteType: 'resend'
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl02() + ApiHelper.name_saveAdminUserApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.showLoader = false;
            await this.sweetAlertHelper.alertPopUp(apiResponse.commandResult.message);
            window.location.reload();
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }
}
