import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-admin-email-log",
  templateUrl: "./admin-email-log.component.html",
  styleUrls: ["./admin-email-log.component.scss"],
})
export class AdminEmailLogComponent implements OnInit {
  loggedInUserId: string;
  loggedInUserRoleId: any;
  loggedInUserEmail:string;
  showLoader = false;
  demographyUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  logsLength: boolean = false;
  logsList: any;
  childRoute: string;
  patientName: any;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  delegatingPhysicianEmail: string;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
    this.demographyUserId = this.activatedRoute.snapshot.paramMap.get("demographyUserId");
    this.loggedInUserEmail = localStorage.getItem("loggedInUserEmail");
    this.delegatingPhysicianEmail = localStorage.getItem('delegatingPhysicianEmail');
  }

  ngOnInit() {
    this.findUserDetails(this.demographyUserId);
    this.findUserEmailLogByPhysician();
  }

  findUserEmailLogByPhysician() {
    const finalData = {
      adminUserId: this.loggedInUserId,
      loggedInUserId: this.demographyUserId,
      company_slug: this.companySlug,
    };
    this.showLoader = true;
  
    let emailLogApi = (this.loggedInUserRoleId == 1) ? ApiHelper.getAdminEmailLogs : ApiHelper.getPhysicianEmailLogs;
  
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + emailLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.logsList.length > 0) {
            // Initial logs fetched
            const allLogs = apiResponse.commandResult.data.logsList;
            
            // Filtering the logs based on loggedInUserEmail
            this.logsList = allLogs.filter(log => {
              const emails = log.PersonEmail.split(',').map(email => email.trim());
              if(this.delegatingPhysicianEmail){
                return emails.includes(this.delegatingPhysicianEmail.trim());
              } else {
                return emails.includes(this.loggedInUserEmail.trim());
              }
            });
  
            // Check if filtered logs length is greater than 0
            this.logsLength = this.logsList.length > 0;
            
          } else {
            this.logsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          console.error(err);  // It's better to use console.error for error logs.
        }
      );
  }
  

  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  //resendDocs(logDetail.RecordId)

  async resendDocs(logId) {
    // if (
    //   confirm("PLEASE CONFIRM: Are you certain you want to open this email?")
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminEmailLogComp.resendDocs
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" +
          this.childRoute +
          "/resend-docs/" +
          this.loggedInUserId +
          "/" +
          this.demographyUserId +
          "/" +
          logId,
      ]);
    // }
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the 'Patient user' list. "
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    // }
  }

  getMaskedString(email) {
    return email.substring(0, 4);
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to physician Home Page?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }

  async goTo2ndopinion(userOptionId) {
    let text = "";
    // 1: 2nd Opinion with Patient Info
    // 2: 2nd Opinion without Patient Info
    // 3: Doctor to Doctor Email with Patient info
    // 4: Doctor to Doctor Email without Patient Info
    // 5: Doctor to Doctor Email
    if (userOptionId == 3) {
      //   var text =
      //     "PLEASE CONFIRM: Are you sure, you want to send email to doctor?";

      text = this.message.confirmMessages.adminEmailLogComp.goTo2ndopinion;
    }
    // const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   text
    // );
    // if (confirmStatus) {
      this.router.navigate([
        "/" +
          this.childRoute +
          "/second-opinion-wf/" +
          this.loggedInUserId +
          "/" +
          this.demographyUserId +
          "/" +
          userOptionId,
      ]);
    // }
  }
}
