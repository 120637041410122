import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { DoctorMailService } from "../../_services/doctor-mail.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

import { UserService } from "../../_services/user.service";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { async } from "@angular/core/testing";
import { id } from "@swimlane/ngx-datatable";
// import { ShareUnrelatedDateService } from "./../../_services/share-unrelated-date.service";

@Component({
  selector: "app-admin-second-option-wf",
  templateUrl: "./admin-second-option-wf.component.html",
  styleUrls: ["./admin-second-option-wf.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminSecondOptionWfComponent implements OnInit {
  @ViewChild("fileUploaded1", { static: false }) fileUploaded1: ElementRef;
  @ViewChild("fileUploaded2", { static: false }) fileUploaded2: ElementRef;
  @ViewChild("fileUploaded3", { static: false }) fileUploaded3: ElementRef;
  @ViewChild("fileUploadedHippa", { static: false })
  fileUploadedHippa: ElementRef;
  showLoader = false;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  loggedInUserId: string;
  userOptionId: string;
  demographicUserId: string;
  hasPhysicianEmail = 1;
  maxChars = 300;
  documentFolderType = "A";
  showNewFile = 0;
  hippaAvailable = false;
  hasHippaFile = false;
  isPracticePhy = false;
  showUserCheckVal = true;
  fileDesTxt: any = "";
  practicingCenterId: any = "";
  hippaFileDesTxt: any = "";
  selectedEmail: any;
  practiceSlug: any = "";
  physicianType = "";
  physicianId: any = "";
  specialtyId: any = "";
  subSpecialtyId: any = "";
  consultantId: any = "";
  company_slug = "";
  stateName = "";
  cityName = "";
  PhysicianName = '';
  PhysicianEmail = '';
  PhysicianNipNo = '';
  PhysicianOfficeAddress = '';
  PhysicianOfficePhone = '';
  PhysicianSpecialty = '';
  PhysicianSubSpecialty = '';
  PhysicianDegree = '';
  stateCode = "";
  attachmentDocment: any = "";
  selectedNPI: any;
  selectedPhyName: any;
  requestingPhyEmailVal = localStorage.getItem("loggedInUserEmail");
  requestingPhyMobileVal = localStorage.getItem("loggedInUserMobile");
  reuestTimeVal: any = "1";
  isWithHipaaVal: any = "1";
  selectedDocumentId: any;
  selectedPhysician: any = {};
  attachmentDoc: any = {};
  docSelected: any = {};
  selected_spacialty_id: any = {};
  selected_sub_specialty_id: any = {};
  selectedDocumentIds: string[] = [];
  selected_spacialty: any = {};
  selected_sub_specialty: any = "";
  checkAltEmail = false;
  statesArre: any = [];
  citiesList: any = [];
  physiciansList: any = [];
  practices: any = [];
  specialityArr: any = [];
  practicingCenterArr: any = [];
  documentsList: any = [];
  hippaDocumentsList: any = [];
  subSpecialtyArr: any = [];
  consultantArr: any = [];
  selectedDocsId: any = [];
  physiciansArr: any = [];
  patientName: any = "";
  fileToUpload1: File = null;
  fSize1: any;
  fileToUpload2: File = null;
  fSize2: any;
  fileToUpload3: File = null;
  fSize3: any;
  fileToUploadHippa: File = null;
  fSizeHippa: any;
  showComfirmationModal = false;
  isOnCall = false;
  showSearchPhysicianModal = false;
  physicianSearchObj: any = {};
  doctorTableArr: any = [];
  doctorTableArrTemp: any = [];
  isSearched = false;
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  selectedStateCode: any;

  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedFiles: any = [];
  physicianCount = 1;
  searchType: any = "1";
  message = Message;
  selectedSpecialityObj: any = {};
  delegateId = localStorage.getItem('delegateId');
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  selectedPhysicianName: any = "";
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private doctorMailService: DoctorMailService,
    public sweetAlertHelper: SweetAlertHelper,
    private userService: UserService // public shareUnrelatedDateService: ShareUnrelatedDateService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
    this.demographicUserId =
      this.activatedRoute.snapshot.paramMap.get("demographicUserId");
    // this.shareUnrelatedDateService.currentData.subscribe(delegateId => (this.selectedDelegateId= delegateId));
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
  }
  ngOnInit() {
    this.findAllParticipatingCenters();
    this.findSpecialtyByCompanySlug(ConstantHelper.COMPANY_SLUG);

    this.findSpecialty(this.loggedInUserId);
    this.findAllStates();
    if (this.userOptionId == "1" || this.userOptionId == "3") {
      this.fnFindAllDocuments(this.demographicUserId);
      this.fnFindHippaDocs(this.demographicUserId, 0);
      this.findUserDetails(this.demographicUserId);
    }
  }

  setSearchTypeVal(e) {
    this.searchType = e.target.value;
  }

  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse.commandResult.data.userInfo);
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findAllStates() {
    const finalCityData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Physician Home Page?"
    //   )
    // )Are you certain you want to return to the Physician Home Page?
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.activatedRoute.snapshot.paramMap.get("loggedInUserId")
      ]);
    // }
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the 'Patient user' list. "
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
      if (this.isDelegated == 0) {
        this.router.navigate([
          "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
        ]);
      } else {
        this.router.navigate([
          "/" + this.childRoute + "/demographics/" + this.assignedDelegateId,
        ]);
      }
    // }
  }

  findAllParticipatingCenters() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.practices = apiResponse.commandResult.data.companiesList;
          } else {
            // alert("No Center of Excellence found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findAllParticipatingCenters
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
          this.showLoader = false;
        }
      );
  }

  findCoeBySubspecialty1(specialtyId, subSpecialtyId) {
    const finalData = {
      specialtyId,
      subSpecialtyId,
    };

    // console.log(finalData);
    // return;
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoeBySubSpeciality,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.practices.length > 0) {
            this.practices = apiResponse.commandResult.data.practices;
          } else {
            //alert("No Center of Excellence found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findAllParticipatingCenters
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByCompanySlug(company_slug) {
    this.physicianId = '';
    this.stateName = '';
    this.cityName = '';
    this.practicingCenterId = '';
    this.selectedPhysician.PhysicianSubSpecialty = '';
    this.selectedPhysician.PhysicianSpecialty = '';
    this.selectedPhysician.PhysicianDegree = '';
    this.selectedPhysician.PhysicianOfficePhone = '';
    this.selectedPhysician.PhysicianOfficeAddress = '';
    this.selectedPhysician.PhysicianNipNo = '';
    this.selectedPhysician.PhysicianEmail = '';
    this.requestingPhyMobileVal = '';
    this.requestingPhyEmailVal = '';
    this.checkDelegateStatus();
    this.company_slug = company_slug;
    const finalData = {
      company_slug,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.get_PhysicianByCompany,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.consultantArr = this.fnChangePhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );
            // console.log('Physicians', this.consultantArr);
          } else {
            // alert("No Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianId = physicianId;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);

    const finalData = {
      physicianNPI: this.selectedPhysician.PhysicianNipNo,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.physicianType = "2";
          this.practiceSlug = ConstantHelper.COMPANY_SLUG;
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.showComfirmationModal = true;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianById(physicianId) {
    // console.log(physicianId);
    this.stateName = '';
    this.cityName = '';
    this.selectedPhysician.PhysicianSubSpecialty = '';
    this.selectedPhysician.PhysicianSpecialty = '';
    this.selectedPhysician.PhysicianDegree = '';
    this.selectedPhysician.PhysicianOfficePhone = '';
    this.selectedPhysician.PhysicianOfficeAddress = '';
    this.selectedPhysician.PhysicianNipNo = '';
   this.selectedPhysician.PhysicianEmail = '';
    const finalDataPhysician = { physicianId };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicianByIdApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePhysician: any) => {
          this.isPracticePhy = true;
          this.physicianType = "1";
          // console.log('apiResponsePhysician', apiResponsePhysician);
          this.stateName =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianStateName;
          this.selectedEmail =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianEmail;

          this.stateCode =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianState;
          this.cityName =
            apiResponsePhysician.commandResult.data.userInfo.PhysicianCity;
          this.selectedPhysician =
            apiResponsePhysician.commandResult.data.userInfo;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.physician_name = this.userService.getUS_FromatName(e.physician_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("physician_name")
    );
  }

  fnChangePhysicianNameFormat1(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  findCitiesByStateCode(stateCode) {
    this.cityName = "";
    this.physicianId = "";
    this.selectedPhysician = {};
    this.stateCode = stateCode;
    this.citiesList = [];
    const finalData = { stateCode };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              // alert("no cities found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcitiesbyStateCode
              );
            }
          } else {
            // alert("no cities found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findcitiesbyStateCode
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.physicianId = "";
    this.selectedPhysician = {};
    const finalDataPhysician = { cityName };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          // console.log(apiResponsePhysician.commandResult.data.physiciansList);
          if (apiResponsePhysician.commandResult.status == 1) {
            // console.log(apiResponsePhysician.commandResult.data.physiciansList);
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physiciansList = this.fnChangePhysicianNameFormat1(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              // alert("No Physicians Found");
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
            }
          } else {
            // alert("No Physicians Found");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  onFileSelected1(event) {
    if (this.fileUploaded1.nativeElement.value != "") {
      this.fileToUpload1 = event.target.files.item(0) as File;
      this.fSize1 = Math.round(this.fileToUpload1.size / 1024);
    } else {
    }
  }

  onFileSelected2(event) {
    if (this.fileUploaded2.nativeElement.value != "") {
      this.fileToUpload2 = event.target.files.item(0) as File;
      this.fSize2 = Math.round(this.fileToUpload2.size / 1024);
    } else {
    }
  }

  onFileSelected3(event) {
    if (this.fileUploaded3.nativeElement.value != "") {
      this.fileToUpload3 = event.target.files.item(0) as File;
      this.fSize3 = Math.round(this.fileToUpload3.size / 1024);
    } else {
    }
  }

  onFileSelectedHippa1(event) {
    if (this.fileUploadedHippa.nativeElement.value != "") {
      this.fileToUploadHippa = event.target.files.item(0) as File;
      this.fSizeHippa = Math.round(this.fileToUploadHippa.size / 1024);
    } else {
    }
  }

  onFileSelectedHippa(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    this.attachmentDoc = {};

    this.hippaAvailable = false;

    if (this.fileUploadedHippa.nativeElement.value != "") {
      this.fileToUploadHippa = event.target.files.item(0) as File;
      this.fSizeHippa = Math.round(this.fileToUploadHippa.size / 1024);
    } else {
    }

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  setAttachment(DocumentId) {
    this.hippaAvailable = false;
    this.fileUploadedHippa.nativeElement.value = "";
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.hippaDocumentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );
    this.attachmentDoc = JSON.stringify(docObj);
    // console.log(this.attachmentDoc);

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }
  clearSelectedDocs(event: any, documentInfo: any) {
    const documentId = documentInfo.RecordId;
    if (event.target.checked) {
      this.selectedFiles.push(documentInfo);
      this.selectedDocsId.push(documentId);
    } else {
      const index = this.selectedFiles.findIndex(file => file.RecordId === documentId);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
        this.removeSelectedFile(documentInfo);
      }

      const idIndex = this.selectedDocsId.indexOf(documentId);
      if (idIndex !== -1) {
        this.selectedDocsId.splice(idIndex, 1);
      }
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    if (this.userOptionId == "4") {
      this.d2d04();
    }

    if (this.userOptionId == "3") {
      this.d2d03();
    }
    if (this.userOptionId == "2") {
      this.d2d02();
    }
    if (this.userOptionId == "1") {
      this.d2d01();
    }
  }

  async d2d04() {
    if (!this.physicianId) {
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: A physician must first be selected prior to emailing a request to share select files with an attached, permitting State HIPAA Form."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1
      );
      return false;
    } else if (
      this.fileUploaded1.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload1.name)
    ) {
      // alert("Selected file 1 format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded1.nativeElement.value != "" &&
      this.fSize1 > 61440
    ) {
      // alert("File 1 too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_1
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload2.name)
    ) {
      // alert("Selected file 2 format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      this.fSize2 > 61440
    ) {
      // alert("File 2 too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_3
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload3.name)
    ) {
      // alert("Selected file 3 format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      this.fSize3 > 61440
    ) {
      // alert("File 3 too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_5
      );
      return false;
    } else if (this.searchType == 2 && !this.selectedPhysician.altEmail) {
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: You must first contact the doctor’s office by telephone to obtain an alternate email address when none is displayed from the NPI database.  First check the phone number with the doctor’s website. Enter an ‘Alternative email address’ to send."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_6
      );
      return false;
    } else if (
      this.selectedPhysician.altEmail &&
      this.selectedPhysician.altEmail != this.selectedPhysician.cnfAltEmail
    ) {
      // alert("Please confirm alternate email address");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_6
      );

      return false;
    } else {
      if (this.searchType == 2) {
        this.selectedEmail = this.selectedPhysician.altEmail;
      }

      // if (
      //   confirm(
      //     "PLEASE CONFIRM:   By clicking ‘OK’: You confirm selecting at least one health information file to share, and are clicking to send the email."
      //   )
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminsecoptwfComp.d2d01
      );
      if (alertStatus) {
        // tslint:disable-next-line: max-line-length
        this.showLoader = true;
        if(this.isDelegated == 1){
          this.loggedInUserId = this.delegateId;
        }
        this.uploadService
          .doctor2DoctorEmail04(
            this.loggedInUserId,
            this.practiceSlug,
            this.physicianType,
            this.stateCode,
            this.cityName,
            this.physicianId,
            this.fileDesTxt,
            11,
            this.userOptionId,
            this.fileToUpload1,
            this.fileToUpload2,
            this.fileToUpload3,
            this.selectedEmail,
            this.assignedDelegateId,
            this.isDelegated,
            this.selectedDelegateId,
            this.selectedPhysicianName
          )
          .subscribe(
            async (data: any) => {
              this.showLoader = false;
              if (data !== undefined) {
                // alert("Email successfully sent. ");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminsecondoptwfCOmp.d2d04_7
                );

                if (this.isDelegated == 0) {
                  this.router.navigate([
                    "/" +
                      this.childRoute +
                      "/demographics/" +
                      this.loggedInUserId,
                  ]);
                } else {
                  this.router.navigate([
                    "/" +
                      this.childRoute +
                      "/demographics/" +
                      this.assignedDelegateId,
                  ]);
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  async d2d03() {
    // this.getCheckedBoxValue();
    if (!this.physicianId) {
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: A physician must first be selected prior to emailing a request to share select files with an attached, permitting State HIPAA Form."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1
      );
      return false;
    } else if (this.selectedDocsId.length <= 0) {
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: To send an email first select the matching State HIPAA Form that lists the doctor’s name and at least one additional file to share."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d03
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      // alert("You can not select more then 5 docs");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_2
      );
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != "" &&
      !this.validateFile(this.fileToUploadHippa.name)
    ) {
      // alert("Selected HIPAA file format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04
      );
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != "" &&
      this.fSizeHippa > 61440
    ) {
      // alert("HIPAA file is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_4
      );
      return false;
    } else if (this.searchType == 2 && !this.selectedPhysician.altEmail) {
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: You must first contact the doctor’s office by telephone to obtain an alternate email address when none is displayed from the NPI database.  First check the phone number with the doctor’s website. Enter an ‘Alternative email address’ to send."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_6
      );
      return false;
    } else if (
      this.selectedPhysician.altEmail &&
      this.selectedPhysician.altEmail != this.selectedPhysician.cnfAltEmail
    ) {
      // alert("Please confirm alternate email address");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_6
      );
      return false;
    } else {
      if (this.searchType == 2) {
        this.selectedEmail = this.selectedPhysician.altEmail;
      }

      // if (
      //   confirm(
      //     "PLEASE CONFIRM:   By clicking ‘OK’: You confirm selecting at least one health information file to share, and are clicking to send the email."
      //   )
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminsecoptwfComp.d2d01
      );
      if (alertStatus) {
        {
          this.showLoader = true;
          this.doctorMailService
            .doctor2DoctorEmail03(
              this.loggedInUserId,
              this.practiceSlug,
              this.physicianType,
              this.stateCode,
              this.cityName,
              this.physicianId,
              this.fileDesTxt,
              11,
              this.userOptionId,
              this.fileToUploadHippa,
              this.selectedDocsId,
              this.hippaFileDesTxt,
              this.showNewFile,
              this.attachmentDoc,
              this.selectedEmail,
              this.showUserCheckVal,
              this.demographicUserId,
              this.assignedDelegateId,
              this.isDelegated,
              this.selectedDelegateId
            )
            .subscribe(
              async (data: any) => {
                if (data !== undefined) {
                  this.showLoader = false;
                  // alert("Email successfully sent. ");
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
                  );
                  if (this.isDelegated == 0) {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.loggedInUserId,
                    ]);
                  } else {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.assignedDelegateId,
                    ]);
                  }
                }
              },
              (error) => {
                // console.log(error);
              }
            );
        }
      }
    }
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        // console.log(element.value);
        this.selectedDocsId.push(element.value);
      }
    }
  }

  fnFindAllDocuments(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findAllDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
            // console.log(this.documentsList);
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindHippaDocs(userId, addToDocList) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      doc_group_id: "HD",
      default_group_id: 17,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            if (addToDocList == 1) {
              this.documentsList = res.commandResult.data.documentList;
            }
            this.hippaDocumentsList = res.commandResult.data.documentList;
          } else {
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  selectDocGroup(docSet) {
    this.documentsList = [];
    if (docSet == "A") {
      this.fnFindAllDocuments(this.demographicUserId);
    } else if (docSet == "U") {
      this.fnFindUploadedDocs(this.demographicUserId);
    } else if (docSet == "DS") {
      this.fnFindDoctorAcknowledgments(this.demographicUserId);
    } else if (docSet == "HD") {
      this.fnFindHippaDocs(this.demographicUserId, 1);
    } else if (docSet == "ID") {
      this.fnFindPhysicainHippa(this.demographicUserId, 9);
    } else if (docSet == "VD") {
      this.fnFindPhysicainHippa(this.demographicUserId, 10);
    } else {
      // this.activeGroupId = docSet;
      // this.fnFindGroupDocs()this.demographicUserId;
    }
  }

  fnFindPhysicainHippa(userId, docType) {
    const finalData = {
      userId,
      docType,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicainHippaApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.documentsList = apiResponse.commandResult.data.documentList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindUploadedDocs(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          // console.log(res)

          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      docType: 7,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_getAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSpecialtyByCompanySlug(specialitSlug) {
    this.checkDelegateStatus();

    this.company_slug = specialitSlug;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    this.specialtyId = "";
    const finalData = {
      companySlug: specialitSlug,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.get_SpecialtyByPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.specialities.length > 0) {
            this.specialityArr = apiResponse.commandResult.data.specialities;
            // console.log('Specialties', this.specialityArr);
            this.docSelected = {};
          } else {
            // alert("No Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSubSpecialtyByCompanySlug(specaltyId) {
    // console.log(specaltyId);
    this.selected_spacialty_id = specaltyId;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    const finalData = {
      companySlug: this.company_slug,
      specialityId: this.selected_spacialty_id,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + "findSubSpecialitiesByCompany", // ApiHelper.get_SubspecialtyBySpecialtyAndPractice,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);

          if (apiResponse.commandResult.data.sub_specialities.length > 0) {
            this.subSpecialtyArr =
              apiResponse.commandResult.data.sub_specialities;
            // console.log('SubSpecialties', this.subSpecialtyArr);
            this.docSelected = {};
            // this.showLoader = false;
          } else {
            // alert("No Sub Specialties found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSubSpeciality
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianBySubspecialty(subSpecaltyId) {
    // console.log(subSpecaltyId);
    this.selected_sub_specialty_id = subSpecaltyId;
    this.docSelected = {};
    this.physicianId = "";
    const finalData = {
      companySlug: this.company_slug,
      specialityId: this.selected_spacialty_id,
      subSpecialityId: this.selected_sub_specialty_id,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_findParticipatingDoctorsBySubSpecialityApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.participating_doctors.length > 0) {
            apiResponse.commandResult.data.participating_doctors.forEach(
              (e) => {
                if (e.is_on_call == 1) {
                  this.isOnCall = true;
                }
              }
            );

            apiResponse.commandResult.data.participating_doctors.forEach(
              (e) => {
                if (this.isOnCall == true) {
                  if (e.is_on_call == 1) {
                    e.showDoctor = true;
                  } else {
                    e.showDoctor = false;
                  }
                } else {
                  if (e.sub_speciality_head == 1) {
                    e.showDoctor = true;
                  } else {
                    e.showDoctor = false;
                  }
                }
              }
            );

            this.physiciansArr = this.fnChangePraticipatingPhysicianNameFormat(
              apiResponse.commandResult.data.participating_doctors
            );
             console.log('Physicians', this.physiciansArr);
          } else {
            this.physiciansArr = {};
            // alert("No physicians found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePraticipatingPhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort("full_name"));
  }

  updatePracticingPhysicianInfo(physicianId) {
    // console.log(physicianId);
    this.docSelected = this.physiciansArr.find(
      (e) => e.physician_id == physicianId
    );

    this.selected_spacialty_id = this.docSelected.speciality_id;
    this.selected_sub_specialty_id = this.docSelected.sub_speciality_id;

    // console.log(this.docSelected);
    this.selectedNPI = this.docSelected.nip_no;
    this.selectedPhyName = this.docSelected.full_name;
  }

  setRequetTimeVal(e) {
    // console.log(e.target.value);
    this.reuestTimeVal = e.target.value;
    if (e.target.value == 1 || e.target.value == 2) {
      this.isWithHipaaVal = "1";
    } else {
      this.isWithHipaaVal = "1";
    }
    // console.log(this.isWithHipaaVal);
  }

  async d2d02() {
    if (
      this.fileUploaded1.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload1.name)
    ) {
      // alert("Selected file 1 format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded1.nativeElement.value != "" &&
      this.fSize1 > 61440
    ) {
      // alert("File 1 too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_1
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload2.name)
    ) {
      // alert("Selected file 2 format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      this.fSize2 > 61440
    ) {
      // alert("File 2 too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_3
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload3.name)
    ) {
      // alert("Selected file 3 format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      this.fSize3 > 61440
    ) {
      // alert("File 3 too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_5
      );
      return false;
    } else {
      this.showLoader = true;
      this.doctorMailService
        .doctor2DoctorEmail02(
          this.loggedInUserId,
          this.practicingCenterId,
          1,
          this.physicianId,
          this.specialtyId,
          this.subSpecialtyId,
          this.fileDesTxt,
          this.requestingPhyMobileVal,
          this.requestingPhyEmailVal,
          this.reuestTimeVal,
          this.userOptionId,
          11,
          this.fileToUpload1,
          this.fileToUpload2,
          this.fileToUpload3,
          this.assignedDelegateId,
          this.isDelegated,
          this.selectedDelegateId
        )
        .subscribe(
          async (data: any) => {
            this.showLoader = false;
            if (data !== undefined) {
              // alert("Email successfully sent. ");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
              );
              if (this.isDelegated == 0) {
                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/demographics/" +
                    this.loggedInUserId,
                ]);
              } else {
                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/demographics/" +
                    this.assignedDelegateId,
                ]);
              }
            }
          },
          (error) => {
            // console.log(error);
          }
        );
    }
  }

  async removeSelectedFile(id) {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to remove this file?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.deleteDoc
    );

    {
      this.selectedDocsId = this.selectedDocsId.filter((obj) => obj !== id);
      let removeIndex = this.selectedFiles
        .map(function (item) {
          return item.RecordId;
        })
        .indexOf(id);
      this.selectedFiles.splice(removeIndex, 1);

      console.log(this.selectedFiles);
    }
  }

  async d2d01() {
    if (this.isWithHipaaVal == 1) {
      // this.getCheckedBoxValue();
    }

    if (!this.physicianId) {
      // tslint:disable-next-line: max-line-length
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: A physician must first be selected prior to emailing a request to share select files with an attached, permitting State HIPAA Form."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1
      );
      return false;
    } else if (this.isWithHipaaVal == 1 && this.selectedDocsId.length <= 0) {
      // tslint:disable-next-line: max-line-length
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: To send an email first select the matching State HIPAA Form that lists the doctor’s name and at least one additional file to share."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_1
      );
      return false;
    } else if (this.isWithHipaaVal == 1 && this.selectedDocsId.length > 5) {
      // alert("You can not select more then 5 docs");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_2
      );
      return false;
      // tslint:disable-next-line: max-line-length
    } else if (
      this.showNewFile == 1 &&
      this.isWithHipaaVal == 1 &&
      this.reuestTimeVal == 3 &&
      this.fileUploadedHippa.nativeElement.value == ""
    ) {
      // alert("Please select or upload HIPAA document");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_3
      );
      return false;
      // tslint:disable-next-line: triple-equals
      // tslint:disable-next-line: max-line-length
    } else if (
      this.isWithHipaaVal == 1 &&
      this.fileUploadedHippa.nativeElement.value != "" &&
      !this.validateFile(this.fileToUploadHippa.name)
    ) {
      // alert("Selected HIPAA file format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04
      );
      return false;
    } else if (
      this.isWithHipaaVal == 1 &&
      this.fileUploadedHippa.nativeElement.value != "" &&
      this.fSizeHippa > 61440
    ) {
      // alert("HIPAA file is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_4
      );
      return false;
    } else if (this.hasPhysicianEmail == 0) {
      // tslint:disable-next-line: max-line-length
      // alert(
      //   "PLEASE CONFIRM:   By clicking ‘OK’: You must first contact the doctor’s office by telephone to obtain an alternate email address when none is displayed from the NPI database.  First check the phone number with the doctor’s website. Enter an ‘Alternative email address’ to send."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_6
      );
      return false;
    } else {
      // tslint:disable-next-line: max-line-length
      // if (
      //   confirm(
      //     "PLEASE CONFIRM:   By clicking ‘OK’: You confirm selecting at least one health information file to share, and are clicking to send the email."
      //   )
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminsecoptwfComp.d2d01
      );
      if (alertStatus) {
        {
          this.showLoader = true;
          // tslint:disable-next-line: max-line-length
          this.doctorMailService
            .doctor2DoctorEmail01(
              this.loggedInUserId,
              this.practicingCenterId,
              1,
              this.physicianId,
              this.specialtyId,
              this.subSpecialtyId,
              this.fileDesTxt,
              11,
              this.userOptionId,
              this.fileToUploadHippa,
              this.selectedDocsId,
              this.hippaFileDesTxt,
              this.showNewFile,
              this.attachmentDoc,
              this.requestingPhyMobileVal,
              this.requestingPhyEmailVal,
              this.reuestTimeVal,
              this.demographicUserId,
              this.assignedDelegateId,
              this.isDelegated,
              this.selectedDelegateId
            )
            .subscribe(
              async (data: any) => {
                this.showLoader = false;
                if (data.commandResult.success == 0){
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    data.commandResult.message
                  );
                } else if (data !== undefined) {
                  // alert("Email successfully sent. ");
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
                  );
                  if (this.isDelegated == 0) {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.loggedInUserId,
                    ]);
                  } else {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.assignedDelegateId,
                    ]);
                  }
                }
              },
              (error) => {
                // console.log(error);
              }
            );
        }
      }
    }
  }

  setHasHipaaVal(e) {
    if (e.target.value == 2) {
      this.showNewFile = 0;
    }
    this.isWithHipaaVal = e.target.value;
  }

  closeSearchPhysicianModal() {
    this.showSearchPhysicianModal = false;
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
  }
  openSearchPhysicianModal() {
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";

    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";

    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  async searchPhysicians() {
    this.checkDelegateStatus();
    if (this.physicianSearchObj.physicianSearchNPI){  
       if(this.physicianSearchObj.physicianSearchNPI.length !== 10 || !/^\d+$/.test(this.physicianSearchObj.physicianSearchNPI)) {
            // Display an error message for invalid NPI length
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.invalidNPI
            );
            return;
          }}
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      // alert(
      //   "Please enter first name. You may search physician informantion by NPI number."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.seacrhPhysician1
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      // alert(
      //   "Please enter last name. You may search physician informantion by NPI number."
      // );
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.searchPhysician
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      // alert("Please select state.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.seacrhPhysician2
      );
      return;
    } else {
      this.doctorTableArr = [];

      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName:
          this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName:
          this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.showLoader = true;

      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_searchNPIRegistryApi,
          finalData,
          this.httpOptions
        )
        // tslint:disable-next-line: deprecation
        .subscribe(
          async (apiResponse: any) => {
            this.doctorTableArr = apiResponse.commandResult.data.physiciansList;
            this.physicianCount = apiResponse.commandResult.data.physiciansCout;
            this.showLoader = false;
            if (this.doctorTableArr.length === 0) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                "No Physicians Found for the given Details"
             );}
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }
  setStateCode(stateCode) {
    this.selectedStateCode = stateCode;
  }

  selectDoctor(physicianId) {
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);
    this.showSearchPhysicianModal = false;
    this.stateCode = this.selectedPhysician.PhysicianState;
    this.cityName = this.selectedPhysician.PhysicianCity;
    this.stateName = this.selectedPhysician.PhysicianStateName;
    this.physicianId = this.selectedPhysician.RecordId;
    this.selectedPhysicianName = this.selectedPhysician.PhysicianName;

    const finalData = { physicianNPI: this.selectedPhysician.PhysicianNipNo };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.physicianType = "2";
          this.practiceSlug = ConstantHelper.COMPANY_SLUG;
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.showComfirmationModal = true;
          }

          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToLink(url: string) {
    let extUrl = "http://" + url.toLowerCase();
    console.log(extUrl);
    window.open(extUrl, "_blank");
  }

  checkDelegateStatus() {
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    // console.log('dss=>',this.selectedDelegateId);
    if (this.selectedDelegateId != this.loggedInUserId) {
      this.loggedInUserId = this.selectedDelegateId;
      this.assignedDelegateId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.isDelegated = 1;
    } else {
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    }
  }

  findSpecialty(loggedInUserId) {
    const finalData = {
      loggedInUserId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getSpecialties,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.specialities.length > 0) {
            this.specialityArr = apiResponse.commandResult.data.specialities;
            // console.log('Specialties', this.specialityArr);
            this.docSelected = {};
          } else {
            // alert("No Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  findSubSpecialtyBySpeciality(speciality) {
    this.selectedSpecialityObj = this.specialityArr.find(
      (e) => e.id == speciality
    );
    this.selected_spacialty = this.selectedSpecialityObj.speciality;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    const finalData = {
      speciality,
      companySlug: this.companySlug
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getSubSpecialties,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.sub_specialities.length > 0) {
            this.subSpecialtyArr =
              apiResponse.commandResult.data.sub_specialities;
            // console.log('SubSpecialties', this.subSpecialtyArr);
            this.docSelected = {};
          } else {
            // alert("No Sub Specialties found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSubSpeciality
            );
            this.resetCoeSelection();
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  findCoeBySubspecialty(subSpecialty) {
    this.selected_sub_specialty = subSpecialty;
    const finalData = {
      speciality: this.selected_spacialty,
      subSpecialty,
    };

    // console.log(finalData);
    // return;
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoeBySubSpeciality,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.practices.length > 0) {
            this.practices = apiResponse.commandResult.data.practices;
          } else {
            // alert("No Center of Excellence found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findAllParticipatingCenters
            );
            this.resetCoeSelection();
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  findCoePhysicians(companySlug) {
    this.company_slug = companySlug;
    this.docSelected = {};
    this.physicianId = "";
    const finalData = {
      companySlug: this.company_slug,
      speciality: this.selected_spacialty,
      subSpeciality: this.selected_sub_specialty,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoePhysicians,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.physicians.length > 0) {
            this.physiciansArr = this.fnChangePraticipatingPhysicianNameFormat(
              apiResponse.commandResult.data.physicians
            );
            this.physiciansArr = this.physiciansArr.filter(
              physician => physician.physician_id != this.loggedInUserId
            );
          } else {
            this.physiciansArr = {};
            // alert("No physicians found!");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
          }

          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }
  isSelected(documentId: string): boolean {
    return this.selectedFiles.some(file => file.RecordId === documentId);
  }
  toggleSelectedDoc(documentId: string) {
    if (this.isSelected(documentId)) {
      this.selectedFiles = this.selectedFiles.filter(id => id !== documentId);
    } else {
      this.selectedFiles.push(documentId);
    }
  }
  resetCoeSelection() {
    this.practicingCenterId = "";
  }

  checkEmail() {
    // Check if the alternate email matches the logged-in user's email
    if (this.selectedPhysician.altEmail && localStorage.getItem("loggedInUserEmail")) {
      if (this.selectedPhysician.altEmail.toLowerCase() === localStorage.getItem("loggedInUserEmail").toLowerCase()) {
        this.checkAltEmail = true;
      } else {
        this.checkAltEmail = false;
      }
    } else {
      this.checkAltEmail = false; // Reset to false if either email is not available
    }
  }
  resetFormControl() {
    this.hippaAvailable = true;
    this.attachmentDocment = '';
    this.fileUploadedHippa.nativeElement.value = '';
  }
  isButtonDisabled(): boolean {
    this.checkEmail();
    return this.checkAltEmail;
  }
}
