import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "./../../_services/user.service";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

declare let $: any;

@Component({
  selector: "app-review-sign",
  templateUrl: "./review-sign.component.html",
  styleUrls: ["./review-sign.component.scss"],
})
export class ReviewSignComponent implements OnInit {
  isUploadAllowed = 0;
  documentMessage = "";
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  fileToUpload: File = null;
  fileSize = 0;
  fileName = "";
  docType = 4;
  showLoader = false;
  parentId: any;
  uploadedDocs: any = [];

  loggedInUserId: string;
  demographicId: string;
  phyicianId: any;
  invitationId: any;
  resultJsonObj: any = {};
  hidePdfPopUp = false;
  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = "";
  documentsLength = false;
  documentsList: any;
  docGroupId: any;
  docGroupTitle: any;
  docSet: any;
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  docGrouObj: any = {};
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any;
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  FullName = "";
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.FullName = "";
    this.showHippaUpdateBtn = localStorage.getItem("showHippaUpdateBtn");
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
    this.invitationId =
      this.activatedRoute.snapshot.paramMap.get("invitationId");
    this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId")
      ? this.activatedRoute.snapshot.paramMap.get("parentId")
      : 0;
    this.uploadedDocs = localStorage.getItem("uploadedDocs")
      ? JSON.parse(localStorage.getItem("uploadedDocs"))
      : [];

    $(document).ready(function () {
      $(window).resize(function () {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $(".right-box .ibox-title").height() +
        $(".right-box .ibox-content").height() -
        45;
      $(".docLists").height(rightHeight);
    }

    // this.fnFindActiveAdminDocuments();
    this.findUserDetails(this.loggedInUserId);
    this.findSignedGroups(this.loggedInUserId);
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('findUserDetails', apiResponse)
          if (apiResponse.commandResult.status == 1) {
            this.docGroupId =
              apiResponse.commandResult.data.userInfo.DocGroupId;
            this.docGroupTitle =
              apiResponse.commandResult.data.userInfo.GroupTitle;
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.FullName = apiResponse.commandResult.data.userInfo.FullName;
            this.findCompanyDefaultGroup(this.loggedInUserId);
            this.activeGroupId = this.profileObj.DocGroupId;
            this.docSet = this.profileObj.DocGroupId;
            this.findInvitationDetails(this.invitationId);
            this.showLoader = false;
          }
        },
        (err) => {
          this.showLoader = false;

          // console.log(err);
        }
      );
  }

  findCompanyDefaultGroup(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: userId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getCompanyDefaultGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companyDefaultGroupTitle =
            apiResponse.commandResult.data.companyDefaultGroup.GroupTitle;
          this.companyDefaultGroupId =
            apiResponse.commandResult.data.companyDefaultGroup.Id;
          this.fnFindGroupDocs();
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;

          // console.log(err);
        }
      );
  }

  showDemographicForm() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(this.resultJsonObj.commandResult.data);

            // this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showHippaForm() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateHippaRealeasPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDisclaimerForm() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_generateWaiverDisclaimerPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(apiResponse);
            // this.disclaimerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }

  async editDemographicForm5() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure, you want to modify your demographic information."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctordocComp.editDemographicForm5
    );
    if (alertStatus) {
      this.router.navigate([
        "/edit-demographic-5/" + this.loggedInUserId + "/" + this.demographicId,
      ]);
    }
  }

  async editSignature() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure, you want to change your signature."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.editSignature
    );
    if (alertStatus) {
      this.router.navigate([
        "/signaturewaiver/" +
          this.loggedInUserId +
          "/" +
          this.demographicId +
          "/1",
      ]);
    }
  }

  showDocument(
    documentId,
    documentUrl,
    docTyep,
    isUploadAllowed,
    documentMessage
  ) {
    // alert(isUploadAllowed)
    // alert(documentMessage)
    this.selectedDocId = documentId;
    this.docTyep = docTyep;
    // console.log('this.docTyep', this.docTyep);
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    this.showDeleteButton = true;
    this.isUploadAllowed = isUploadAllowed;
    this.documentMessage = documentMessage ? documentMessage : "some message";
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to Cancel and return to Home Page."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsFormComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId + "/1"]);
    }
  }

  async goToRegisteredDoctors() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to leave and going back to Home Page."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      window.open(
        this.userService.parentSiteUrl() +
          "/registered-doctors/" +
          this.loggedInUserId,
        "_self"
      );
    }
  }

  selectDocGroup(docSet) {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.showDeleteButton = false;
    if (docSet == "A") {
      this.fnFindAllDocuments();
    } else if (docSet == "U") {
      this.fnFindUploadedDocs();
    } else if (docSet == "DS") {
      this.fnFindDoctorAcknowledgments();
    } else {
      this.activeGroupId = docSet;
      this.fnFindGroupDocs();
    }
  }

  fnFindActiveAdminDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
          ApiHelper.name_getActiveAdminDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindAllDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.profileObj.Email,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findAllDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('All Documents', this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDefaultDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.profileObj.Email,
    };

    // console.log('some', finalData)

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDefaultDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Default Documents', this.resultJsonObj);

          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindGroupDocs() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      doc_group_id: this.activeGroupId,
      default_group_id: this.companyDefaultGroupId,
      email: this.profileObj.Email,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findTempDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Sub Group Documents', this.resultJsonObj);
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;

          // console.log(err);
        }
      );
  }

  fnFindUploadedDocs() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      email: this.profileObj.Email,
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Uploaded Documents', this.resultJsonObj);
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async deleteDoc() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to delete"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.deleteDoc
    );
    {
      const finalData = {
        docId: this.selectedDocId,
      };
      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deleteDocApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
            this.showDeleteButton = false;
            this.fnFindUploadedDocs();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async clearViewr() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.clearViewr
    );
    {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.showDeleteButton = false;
    }
  }

  ////////////////////////////////////////////////////////

  findSignedGroups(userId) {
    this.showLoader = true;

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getSignatureGroupsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.signedGroups = apiResponse.commandResult.data.DocGroupd;
          this.signedGroupsCount =
            apiResponse.commandResult.data.DocGroupd.length;
          this.showLoader = false;

          this.fnFindGroupDocs();
        },
        (err) => {
          this.showLoader = false;

          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      docType: 7,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_getAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async signOff() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: After submitting, you should receive all documents by email."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctordocComp.signOff
    );
    if (alertStatus) {
      this.showDocThumbnails = false;
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        demographicId: this.demographicId,
        company_slug: ConstantHelper.COMPANY_SLUG,
        phyicianId: this.phyicianId,
        invitationId: this.invitationId,
      };
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_sendSignOffMailDocGroupApi,
          finalData,
          options
        )
        .subscribe(
          async (apiResponse) => {
            this.resultJsonObj = apiResponse;
            // this.router.navigate(['/dashboard/' + this.loggedInUserId]);
            // alert("Demographics Form submitted succesfully.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.documentThoubsComp.signOff
            );

            if (this.parentId > 0) {
              window.open(
                this.userService.parentSiteUrl() +
                  "/registered-doctors/" +
                  this.parentId,
                "_self"
              );
            } else {
              window.open(
                this.userService.parentSiteUrl() +
                  "/registered-doctors/" +
                  this.loggedInUserId,
                "_self"
              );
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  findInvitationDetails(invitationId) {
    const finalData = {
      invitationId: invitationId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findInvitationDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          console.log(apiResponse);
          this.docGrouObj = apiResponse.commandResult.data.docGroupInfo;
          this.docSet = apiResponse.commandResult.data.id;
          this.documentsList = apiResponse.commandResult.data.documentList;
          this.documentsLength = true;
          console.log("this.docGrouObj->", this.docGrouObj);
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;

          // console.log(err);
        }
      );
  }

  // uploadDocument(event) {
  //     if (this.fileUploaded.nativeElement.value !== '') {
  //         this.fileToUpload = event.target.files.item(0) as File;
  //         this.fileSize = Math.round(this.fileToUpload.size / 1024);
  //         this.fileName = this.fileToUpload.name;
  //         if (this.fileSize > 61440) {
  //             alert('File is too Big, please select a file less than 60mb');
  //             return;
  //         } else {
  //             if (confirm('PLEASE CONFIRM: Are you sure you want to upload the file?')) {
  //                 this.showLoader = true;
  //                 this.uploadService.uploadAdminReviewFile(
  //                     this.loggedInUserId,
  //                     this.demographicId,
  //                     this.fileToUpload,
  //                     this.docType,
  //                     this.fileName,
  //                     this.phyicianId
  //                 ).subscribe((data: any) => {
  //                     if (data !== undefined) {
  //                         this.showLoader = false;
  //                         this.fileUploaded.nativeElement.value = '';
  //                         if (data.commandResult.success == 1) {
  //                             alert('File successfully upload.');
  //                         } else {
  //                             alert('Upload failed, try again');
  //                         }
  //                     }
  //                 }, error => {
  //                     this.showLoader = false;
  //                     this.fileUploaded.nativeElement.value = '';
  //                     console.log(error);
  //                 });
  //             }
  //         }
  //     }
  // }

  viewDocument(event, documentUrl: any) {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
  }
}
