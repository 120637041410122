import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import Swal from "sweetalert2";

@Component({
  selector: "app-doctor-profile",
  templateUrl: "./doctor-profile.component.html",
  styleUrls: ["./doctor-profile.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class DoctorProfileComponent implements OnInit {
  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {};
  doctorObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  childRoute: string;
  que01SelectDisabledOption = true;
  que02SelectDisabledOption = true;
  statesArre = ArrayHelper.STATE_ARRAY;
  showLoader = false;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.doctorObj.PhysicianSecondaryState = "";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findUserDetails(this.loggedInUserId);
    this.findDoctorByUserId(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.que01SelectDisabledOption = false;
          this.firstQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.que02SelectDisabledOption = false;
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findDoctorByUserId(userId) {
    const finalData = {
      userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDoctorByUserIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.status === 1) {
            this.doctorObj = apiResponse.commandResult.data.npiInfo;
            if (this.doctorObj.siteUrl == "undefined") {
              this.doctorObj.siteUrl = "";
            }
            if (this.doctorObj) this.showLoader = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Home Page?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsFormComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }

  onSubmit(f) {
    if (!f.dirty) {
      Swal.fire({
        title: "No Change",
        text: "Your profile has not been updated",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.router.navigate([
            "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
          ]);
        }
      });
    } else {
      Swal.fire({
        title: "PLEASE CONFIRM",
        text: "Are you sure you want to make these changes?",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          const finalData = {
            company_slug: ConstantHelper.COMPANY_SLUG,
            userId: this.profileObj.RecordId,
            fullName: this.profileObj.FullName,
            mobile: this.profileObj.Mobile,
            ans_01: this.profileObj.Ans_01,
            ans_02: this.profileObj.Ans_02,
            queId_01: this.profileObj.QueId_01,
            queId_02: this.profileObj.QueId_02,

            npiId: this.doctorObj.Id,
            officeState: this.doctorObj.PhysicianState,
            officeCity: this.doctorObj.PhysicianCity,
            officeAddress: this.doctorObj.PhysicianOfficeAddress,
            streeAddOne: this.doctorObj.PhysicianStreet01,
            streeAddTwo: this.doctorObj.PhysicianStreet02,
            siteUrl: this.doctorObj.siteUrl,
            PracticeName: this.doctorObj.PracticeName,
            zipCode: this.doctorObj.PhysicianZipCode,
            officePhone: this.doctorObj.PhysicianOfficePhone,
            degree: this.doctorObj.PhysicianDegree,
            npiNo: this.doctorObj.PhysicianNPI,
            specialtiy: this.doctorObj.PhysicianSpecialty,
            subSpecialtiy: this.doctorObj.PhysicianSubSpecialty,
            primaryState: this.doctorObj.PhysicianPrimaryState,
            primaryStateLicense: this.doctorObj.PhysicianPrimaryStateLicense,
            secondryState: this.doctorObj.PhysicianSecondaryState,
            secondryStateLicense: this.doctorObj.PhysicianSecondaryStateLicense,
          };
          this.showLoader = true;
          return this.http
            .post(
              this.appConfig.getPhpUrl() + ApiHelper.php_updateDoctorProfileApi,
              finalData,
              this.httpOptions
            )
            .subscribe(
              async (apiResponse: any) => {
                // alert("Profile updated successfully.");
                this.showLoader = false;
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.doctorProfileComp.onSubmit1
                );

                window.location.reload();
              },
              (err) => {
                // console.log(err);
              }
            );
        }
      });
    }
  }

  // async onSubmit() {
  //   // if (
  //   //   confirm(
  //   //     "PLEASE CONFIRM: Are you sure you want to continue with the given information?"
  //   //   )
  //   // )
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.doctorProfileComp.onSubmit
  //   );
  //   if (alertStatus) {
  // const finalData = {
  //   company_slug: ConstantHelper.COMPANY_SLUG,
  //   userId: this.profileObj.RecordId,
  //   fullName: this.profileObj.FullName,
  //   mobile: this.profileObj.Mobile,
  //   ans_01: this.profileObj.Ans_01,
  //   ans_02: this.profileObj.Ans_02,
  //   queId_01: this.profileObj.QueId_01,
  //   queId_02: this.profileObj.QueId_02,

  //   npiId: this.doctorObj.Id,
  //   officeState: this.doctorObj.PhysicianState,
  //   officeCity: this.doctorObj.PhysicianCity,
  //   officeAddress: this.doctorObj.PhysicianOfficeAddress,
  //   streeAddOne: this.doctorObj.PhysicianStreet01,
  //   streeAddTwo: this.doctorObj.PhysicianStreet02,
  //   siteUrl: this.doctorObj.siteUrl,
  //   PracticeName: this.doctorObj.PracticeName,
  //   zipCode: this.doctorObj.PhysicianZipCode,
  //   officePhone: this.doctorObj.PhysicianOfficePhone,
  //   degree: this.doctorObj.PhysicianDegree,
  //   npiNo: this.doctorObj.PhysicianNPI,
  //   specialtiy: this.doctorObj.PhysicianSpecialty,
  //   subSpecialtiy: this.doctorObj.PhysicianSubSpecialty,
  //   primaryState: this.doctorObj.PhysicianPrimaryState,
  //   primaryStateLicense: this.doctorObj.PhysicianPrimaryStateLicense,
  //   secondryState: this.doctorObj.PhysicianSecondaryState,
  //   secondryStateLicense: this.doctorObj.PhysicianSecondaryStateLicense,
  // };
  // this.showLoader = true;
  // return this.http
  //   .post(
  //     this.appConfig.getPhpUrl() + ApiHelper.php_updateDoctorProfileApi,
  //     finalData,
  //     this.httpOptions
  //   )
  //   .subscribe(
  // async (apiResponse: any) => {
  //   // alert("Profile updated successfully.");
  //   const alertStatus = await this.sweetAlertHelper.alertPopUp(
  //     this.message.alertMessages.doctorProfileComp.onSubmit
  //   );

  //   window.location.reload();
  //   this.showLoader = false;
  // },
  //   (err) => {
  //     // console.log(err);
  //   }
  // );
  //   }
  // }
}
