import { Component, OnInit } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { Subject } from "rxjs";
import * as XLSX from 'xlsx';
interface UserData {
  fullName: string;
  mobile: string;
  email: string;
  docId: number;
}
@Component({
  selector: "app-admin-invite-patient",
  templateUrl: "./admin-invite-patient.component.html",
  styleUrls: ["./admin-invite-patient.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminInvitePatientComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  loggedInUserId: string;

  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  groupsList: any = [];
  groupsLength = false;
  addAdminObj: any = {};
  userAdded = false;
  existingMobile = false;
  existingEmail = false;
  invitePatientLogsList: any = [];
  newUser: any = [];
  ExistingUser: any =[];
  registeredUser : any =[];
  dataSource: any;
  bulkPatientApiData: any;
  bulkPatientApiDataFlag: boolean = false;
  fileUploaded: boolean = false;


  // documentGroups: string[] = []; 
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  showLoader = false;
  userList: UserData[] = [];
  file: File | null = null;
  rawData: any;
  patientData: any = [];
  
  
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.dtOptions = {
      order: [],
    };
    this.addAdminObj.DocGroupId = "";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findDocumentGroups();
    this.findInvitePatientLogs();
  }

  findDocumentGroups() {
    const finalDataDocGroup = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDocGroupsByCompanyApi,
        finalDataDocGroup,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.groupList.length > 0) {
            this.groupsLength = true;
            this.groupsList = apiResponse.commandResult.data.groupList;
            // console.log('groupsList', this.groupsList);
          } else {
            this.groupsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onSubmit() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminInvitePatientComp.onSubmit
    );
    if (alertStatus) {
      {
        const httpHeaders = new HttpHeaders({
          "Content-Type": "application/json",
        });
        const options = {
          headers: httpHeaders,
        };

        const finalData = {
          company_slug: ConstantHelper.COMPANY_SLUG,
          fullName: this.addAdminObj.fullName,
          mobile: this.addAdminObj.mobile,
          email: this.addAdminObj.email,
          groupId: this.addAdminObj.DocGroupId,
          loggedInUserId: this.loggedInUserId,
        };

        // console.log(finalData);
        return this.http
          .post(
            this.appConfig.getPhpUrl() + ApiHelper.php_sendInvitationApi,
            finalData,
            options
          )
          .subscribe(
            async (apiResponse: any) => {
              if (apiResponse.commandResult.success === -1) {
                await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminInvitePatientComp.onSubmit
                );
              } else {
                await this.sweetAlertHelper.alertPopUp(
                  apiResponse.commandResult.message
                );
                window.location.reload();
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }


  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  async goToDemographics() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId + "/0",
      ]);
    // }
  }

  async findInvitePatientLogs() {
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: httpHeaders };

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.patientInviteLog,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse: any) => {
          this.showLoader = false;
          this.invitePatientLogsList = apiResponse.commandResult.data.logsList;
          this.dtTrigger.next(null);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async resendInvite(invitationId) {
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: httpHeaders };
    const finalData = {
      invitationId,
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_resendPatientInvite,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse: any) => {
          this.showLoader = false;
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            apiResponse.commandResult.message
          );
          if (alertStatus) {
            window.location.reload();
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  
  downloadExcel(): void {
    const filename = 'BulkInvite.xlsx';
    const filePath = '/assets/'+ filename;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', filePath);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
onFileChange(event: any) {
  const reader = new FileReader();
  console.log("lneght", event.target.files.length)
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    reader.readAsArrayBuffer(file);
    reader.onload = (e: any) => {
      const arrayBuffer = e.target.result;
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array', cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      // Filter rows with all columns filled
      const filteredData = jsonData.filter(row => {
        for (const key in row ) {
          if (row.hasOwnProperty(key) && !row[key]) {
            return false; // If any column is empty, return false
          }
        }
        return true; // All columns filled, return true
      });

      this.dataSource = filteredData;

      for(let i = 0; i < this.dataSource.length; i++){
        this.patientData.push(this.dataSource[i]);
        // console.log(this.dataSource[i]);
      }
    };
    this.fileUploaded = true;
  }
  else{
    this.fileUploaded = false;
  }
}

removeUser(user: any) {
  const index = this.dataSource.indexOf(user);
  if (index !== -1) {
      this.dataSource.splice(index, 1); 
      this.patientData.splice(index,1);
  }
}

async bulkInvite() {
  const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    this.message.confirmTitle,
    this.message.confirmMessages.adminInvitePatientComp.onSubmit
  );
  if (alertStatus) {
    {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
        const finalData = {
          patientData: this.patientData.map(patient => ({
            company_slug: ConstantHelper.COMPANY_SLUG,
            fullName: `${patient.lastName} ${patient.firstName}`, // Concatenate first name and last name
            mobile: patient.mobile,
            email: patient.email,
            documentGroupID: patient.documentGroupID,
            loggedInUserId: this.loggedInUserId,
          }))
        };
        this.showLoader = true;
        this.http
            .post(
                this.appConfig.getLambdaUrl06() + ApiHelper.bulkInviteAPI,
                finalData,
                options
            )
            .subscribe(
                async (apiResponse: any) => {
                  this.showLoader = false;
                  this.newUser = apiResponse.commandResult.data.newUsers;
                  this.ExistingUser = apiResponse.commandResult.data.existingUsers;
                  this.registeredUser = apiResponse.commandResult.data.registeredUser;
                  const Bulkinvite = this.newUser.concat(this.ExistingUser, this.registeredUser);
                  this.bulkPatientApiData = Bulkinvite;
                  this.bulkPatientApiDataFlag = true
                  console.log("Bulkinvite" , Bulkinvite);
                    if (apiResponse.commandResult.success === -1) {
                        await this.sweetAlertHelper.alertPopUp(
                            this.message.alertMessages.adminInvitePatientComp.onSubmit
                        );
                    } else {
                        await this.sweetAlertHelper.alertPopUp(
                            apiResponse.commandResult.message
                        );
                      //  await window.location.reload();
                    }
                },
                (err) => {
                    // console.log(err);
                }
            );
    // }
  }    
  }
}

}
