import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UserService} from './../../_services/user.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {ShareUnrelatedDateService} from './../../_services/share-unrelated-date.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {ApiService} from './api.service';
import {ChangeDetectorRef} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
})
export class DemographicsComponent implements OnInit {
  loggedInUserId: string;
  loggedInUserRoleId: any;
  showLoader = false;
  isHipaa: any;
  resultJsonObj: any = {};
  usersLength = false;
  usersList: any = [];
  tempLog: any;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  comapnySlug: string = ConstantHelper.COMPANY_SLUG;

  childRoute: string;
  physicianVal: any = '';
  patientsTypeVal = localStorage.getItem('patientsTypeVal') ? localStorage.getItem('patientsTypeVal') : '1';
  showModificationAlert = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  physicianArr: any = [];

  delegateId: any;
  delegationArr: any = [];
  isDelegating: any = 0;
  selectedDelegate: any = {};
  showDelegationOption: any = 1;
  message = Message;
  dtOptions: DataTables.Settings = {};
  envName = environment.environmentName;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    public shareUnrelatedDateService: ShareUnrelatedDateService
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
    this.isHipaa = this.activatedRoute.snapshot.paramMap.get("isHipaa");
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;

    this.shareUnrelatedDateService.currentData.subscribe(
      (usersList) => (this.usersList = usersList)
    );
    this.shareUnrelatedDateService.currentData.subscribe(
      (tempLog) => (this.tempLog = tempLog)
    );
    this.shareUnrelatedDateService.currentSData.subscribe(
      (physicianVal) => (this.physicianVal = physicianVal)
    );
    this.shareUnrelatedDateService.currentData2.subscribe(
      (isDelegating) => (this.isDelegating = isDelegating)
    );
    // this.shareUnrelatedDateService.currentData3.subscribe(
    //   (patientsTypeVal) => (this.patientsTypeVal = patientsTypeVal)
    // );
  }

  ngOnInit() {
    this.isDelegating = localStorage.getItem("isDelegating")
      ? localStorage.getItem("isDelegating")
      : 0;
    this.delegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;

    // alert(localStorage.getItem('delegateId'));
    if (this.loggedInUserRoleId == 1) {
      this.fnFindPhysiciansList();
      if (localStorage.getItem("isDelegating") == "1") {
        this.fnFindPatientsByPhysiciaId(this.delegateId, 0);
      } else {
        this.fnFindPatientList(0);
      }
    } else {
      if(this.patientsTypeVal == '1'){
        this.fnFindPatientsByPhysiciaId(this.delegateId, 0);
      } else {
        this.searchPatients(this.patientsTypeVal)
      }
    }
    this.findDelegationsByAdmin();
    if(this.isDelegating == 1 || this.loggedInUserRoleId == 4){
      this.dtOptions = {
        stateSave : true,
        order: [[16, 'desc'], [0, 'asc']]
      };
    }
  }

  setdelegateId(userId) {
    if (userId != this.loggedInUserId) {
      this.selectedDelegate = this.delegationArr.find(
        (e) => e.physician_id == userId
      );
      localStorage.setItem('delegateId', userId);
      localStorage.setItem('isDelegating', '1');
    } else {
      localStorage.removeItem('delegateId');
      localStorage.removeItem('isDelegating');
    }
  }

  resetDelegation() {
    localStorage.removeItem('delegateId');
    localStorage.removeItem('isDelegating');
  }

  findDelegationsByAdmin() {
    const finalData = {
      adminId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findDelegationsByAdminApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          // console.log(res);
          this.delegationArr = res.commandResult.data.delegations;

          this.delegateId = localStorage.getItem('delegateId')
            ? localStorage.getItem('delegateId')
            : this.loggedInUserId;
          this.isDelegating = localStorage.getItem('isDelegating')
            ? localStorage.getItem('isDelegating')
            : 0;
          if (localStorage.getItem('isDelegating') == '1') {
            this.selectedDelegate = this.delegationArr.find(
              (e) => e.physician_id == localStorage.getItem('delegateId')
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  searchPatients(patientsTypeVal) {
    this.usersList = [];
    let doctorId = this.physicianVal ? this.physicianVal : this.loggedInUserId;
    if (patientsTypeVal == '1') {
      this.fnFindPatientsByPhysiciaId(this.delegateId, 0);
      localStorage.setItem('patientsTypeVal', '1');
    } else if (patientsTypeVal == '2') {
      this.fnFindPatientsByPhysiciaId(this.delegateId, 1);
      localStorage.setItem('patientsTypeVal', '2');
    } else if (patientsTypeVal == '3') {
      this.fnFindPatientsByPhysiciaId(this.delegateId, 2);
      localStorage.setItem('patientsTypeVal', '3');
    } else if (patientsTypeVal == 4) {
      this.fnFindPatientList(0);
      localStorage.setItem('patientsTypeVal', '4');
    } else if (patientsTypeVal == 5) {
      this.fnFindPatientList(1);
      localStorage.setItem('patientsTypeVal', '5');
    } else if (patientsTypeVal == 6) {
      this.fnFindPatientList(2);
      localStorage.setItem('patientsTypeVal', '6');
    }
  }

  searchPatients1() {
  }

  fnFindPatientsByPhysiciaId(loggedInUserId, ageValue) {
    this.usersList = [];
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: loggedInUserId,
    };
    console.log(finalData);
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getUsersListByPhysicianId,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (
            this.resultJsonObj.commandResult.data.demographicUsers.length > 0
          ) {
            this.usersLength = true;
            this.usersList =
              this.resultJsonObj.commandResult.data.demographicUsers;
            if(ageValue == 1){
              this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age <= 45)
            }
            if (ageValue == 2){
              this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age > 45)
            }
            // console.log(this.usersList);

            this.tempLog =
              this.resultJsonObj.commandResult.data.demographicUsers;
          } else {
            this.usersLength = false;
            this.usersList = [];
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPhysiciansList() {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findDoctorsWithUserApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          console.log(res.commandResult.data.comapny_physicians);
          this.physicianArr = this.fnChangePhysicianNameFormat(
            res.commandResult.data.comapny_physicians
          );
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPatientList(ageValue) {
    this.usersList = [];
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.delegateId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getUsersListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (
            this.resultJsonObj.commandResult.data.demographicUsers.length > 0
          ) {
            this.usersLength = true;
            this.usersList =
              this.resultJsonObj.commandResult.data.demographicUsers;
            // console.log(this.usersList);
            if(ageValue == 1){
              this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age <= 45);
            }
            if(ageValue == 2){
              this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age > 45);
            }
            this.tempLog =
              this.resultJsonObj.commandResult.data.demographicUsers;
          } else {
            this.usersLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNamePphy: any = [];
    physicians.forEach((e) => {
      if (e.PrimaryPhysician != '-') {
        e.PrimaryPhysician = this.userService.getUS_FromatName(
          e.PrimaryPhysician
        );
        formattedNamePphy.push(e);
      }
    });
    return formattedNamePphy.sort(
      this.userService.dynamicSort('PrimaryPhysician')
    );
  }

  fnChangePhysicianNameFormat3(physicians3) {
    const formattedNameArray3: any = [];
    physicians3.forEach((e) => {
      e.form1PrimaryPhysician = this.userService.getUS_FromatName(
        e.form1PrimaryPhysician
      );
      formattedNameArray3.push(e);
    });
    return formattedNameArray3.sort(
      this.userService.dynamicSort('form1PrimaryPhysician')
    );
  }

  async goToUserEmailLog(userId) {
    // if (confirm('PLEASE CONFIRM: Are you sure, you want to access email logs?'))
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.goToUserEmailLog
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/email-logs/' +
        this.loggedInUserId +
        '/' +
        userId,
      ]);
    // }
  }

  async goToUserEmailDoc(userId, demographicId) {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to email docs?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.goToUserEmailDoc
    );
    if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/email-docs/' +
        this.loggedInUserId +
        '/' +
        userId +
        '/' +
        demographicId,
      ]);
    }
  }

  async goToSendEmail(userId, demographicId) {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to send an email to this patient?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.goToSendEmail
    );
    if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/send-email/' +
        this.loggedInUserId +
        '/' +
        userId +
        '/' +
        demographicId,
      ]);
    }
  }

  async goToUserDocGroup(userId, demographicId) {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure, you want to assign new doc group?")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.goToUserDocGoupComp
    );
    if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/assign-doc-group/' +
        this.loggedInUserId +
        '/' +
        userId +
        '/' +
        demographicId,
      ]);
    }
  }

  async goToUHF(userId, demographicId) {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure, you want to Send HIPAA to patient?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.goToUHF
    );
    if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/ushf/' +
        this.loggedInUserId +
        '/' +
        userId +
        '/' +
        demographicId,
      ]);
    }
  }

  async goToDocumentReview(userId, demographicId) {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure, you want to review patient's documents?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.goToDocumentReview
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/doc-review/' +
        this.loggedInUserId +
        '/' +
        userId +
        '/' +
        demographicId,
      ]);
    // }
  }

  async goToDocumentUpload(userId, demographicId) {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure, you want to upload documents?")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.goToDocumentUpload
    );
    if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/upload-doc/' +
        this.loggedInUserId +
        '/' +
        userId +
        '/' +
        demographicId,
      ]);
    }
  }

  async goTo2ndopinion(optionId) {
    // 1: 2nd Opinion with Patient Info
    // 2: 2nd Opinion without Patient Info
    // 3: Doctor to Doctor Email with Patient info
    // 4: Doctor to Doctor Email without Patient Info
    // 5: Doctor to Doctor Email
    const text = '';
    let confMsg = '';
    if (optionId == 1) {
      // text = "PLEASE CONFIRM: Are you sure, you want to go for 2nd opinion?";
      confMsg =
        this.message.confirmMessages.demographicsComp.goToseconfopinionWf;
    } else if (optionId == 2) {
      // text = "PLEASE CONFIRM: Are you sure, you want to go for 2nd opinion?";
      confMsg =
        this.message.confirmMessages.demographicsComp.goToseconfopinionWf;
    } else if (optionId == 3) {
      // text = "PLEASE CONFIRM: Are you sure, you want to send email to doctor?";
      confMsg = this.message.confirmMessages.demographicsComp.goTo2ndOpinion;
    } else if (optionId == 4) {
      // text = "PLEASE CONFIRM: Are you sure, you want to send email to doctor?";
      confMsg = this.message.confirmMessages.demographicsComp.goTo2ndOpinion;
    } else if (optionId == 5) {
      // text = "PLEASE CONFIRM: Are you sure, you want to send email to doctor?";
      confMsg = this.message.confirmMessages.demographicsComp.goTo2ndOpinion;
    }
    // const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   confMsg
    // );
    // if (confirmStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/second-opinion-wf/' +
        this.loggedInUserId +
        '/' +
        optionId,
      ]);
    // }
  }

  async goTo2ndopinionWF(optionId, demographicUserId) {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure, you want to go for 2nd opinion?")
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.goToseconfopinionWf
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/second-opinion-wf/' +
        this.loggedInUserId +
        '/' +
        demographicUserId +
        '/' +
        optionId,
      ]);
    // }
  }

  async invitePatient() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to invite a new patient?")
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.invitePatient
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/invite-patient/' + this.loggedInUserId,
      ]);
    // }
  }

  async email2All() {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to send email?"))
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.email2All
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/email2all/' + this.loggedInUserId,
      ]);
    // }
  }

  checkValidTest(text) {
    if (text == 'N/A' || text == 'NA' || text == '-') {
      return '';
    } else {
      return text;
    }
  }

  getMaskedString(email) {
    return email.substring(0, 4);
  }

  async goToPatientEmail(patientId) {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to send email?"))
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.email2All
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/email2user/' +
        this.loggedInUserId +
        '/' +
        patientId,
      ]);
    // }
  }

  searchReport() {
  }

  filterPhysician(physicianId) {
    this.fnFindPatientsByPhysiciaId(physicianId, null);
  }

  resetSearch() {
    window.location.reload();
  }

  async addToMyList(patientId, status) {
    let confMsg = '';
    let altMsg = '';
    if (status == 'A') {
      confMsg = this.message.confirmMessages.demographicsComp.addToMyList;
      altMsg = this.message.alertMessages.demographicsComp.addToMyList;
    } else {
      confMsg = this.message.confirmMessages.demographicsComp.addToMyList1;
      altMsg = this.message.alertMessages.demographicsComp.addToMyList1;
    }
    const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      confMsg
    );


    if (confirmStatus) {
      this.usersList = [];
      const patientsTypeValAsNumber = parseInt(this.patientsTypeVal, 10);
      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        loggedInUserId: this.delegateId,
        patientId: patientId,
        status: status,
        patientsType: (patientsTypeValAsNumber == 1 || patientsTypeValAsNumber == 2 || patientsTypeValAsNumber == 3) ? '1' : (patientsTypeValAsNumber == 4 || patientsTypeValAsNumber == 5 || patientsTypeValAsNumber == 6) ? '2' : undefined
      };
      this.showLoader = true;

      // return
      this.http
        .post(
          this.appConfig.getPhpUrl() +
          ApiHelper.php_addRemovepataintFromDoctorListApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.sweetAlertHelper.alertPopUp(altMsg);
            this.resultJsonObj = apiResponse;
            console.log(this.resultJsonObj);
            if (
              this.resultJsonObj.commandResult.data.demographicUsers.length > 0
            ) {
              this.usersLength = true;
              
              if (this.patientsTypeVal == '1'){
                this.usersList = this.resultJsonObj.commandResult.data.demographicUsers;
                this.patientsTypeVal = '1';
              }
              else if(this.patientsTypeVal == '2'){
                this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age <= 45)
                this.patientsTypeVal = '2';
              }
              else if (this.patientsTypeVal == '3'){
                this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age > 45)
                this.patientsTypeVal = '3';
              }
              else if(this.patientsTypeVal == '4'){
                this.usersList = this.resultJsonObj.commandResult.data.demographicUsers;
                this.patientsTypeVal = '4';
              }
              else if(this.patientsTypeVal == '5'){
                this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age <= 45);
                this.patientsTypeVal = '5';
              }
              else if (this.patientsTypeVal == '6'){
                this.usersList = this.resultJsonObj.commandResult.data.demographicUsers.filter(user => user.form1Age > 45);
                this.patientsTypeVal = '6';
              }
              this.tempLog =
                this.resultJsonObj.commandResult.data.demographicUsers;
            } else {
              this.usersLength = false;
              this.usersList = [];
            }
            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async goToUserComments(userObj) {
    console.log('userObj', userObj);
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.goToUserComments
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/comments/' +
        this.loggedInUserId +
        '/' +
        userObj.DemographicId,
      ]);
    // }
  }

  async goToPatientHistory(patientId) {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to send email?"))
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.demographicsComp.email2All
    // );
    // if (alertStatus) {
      this.router.navigate(["/" + this.childRoute + "/patient-history/" + this.loggedInUserId + "/" + patientId + "/1"]);
    // }
  }

  async goToShareDocument(userId, demographicId) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.goToDocumentUpload
    );
    if (alertStatus) {
      this.router.navigate([
        '/' +
        this.childRoute +
        '/upload-document/' +
        this.loggedInUserId +
        '/' +
        userId +
        '/' +
        demographicId,
      ]);
    }
  }
}
