import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {ArrayHelper} from './../../_helpers/array.helper';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {async} from '@angular/core/testing';

@Component({
  selector: 'app-admin-change-password',
  templateUrl: './admin-change-password.component.html',
  styleUrls: ['./admin-change-password.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class AdminChangePasswordComponent implements OnInit {
  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  showLoader = false;
  message = Message;
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  password: any;
  cnfPassword: any;
  passwordForm: any;
  fieldTextType: boolean = false;
  newPassword: any = '';
  confirmNewPassword: any = '';
  passwordMismatch: boolean = false;
  cnFieldTextType: boolean = false;
  zone: any;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.findUserDetails(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      if (this.loggedInUserRoleId == '1') {
        this.router.navigate(['admin/dashboard/' + this.loggedInUserId]);
      } else if (this.loggedInUserRoleId == '4') {
        this.router.navigate(['doctor/dashboard/' + this.loggedInUserId]);
      }
    }
  }

  // async onSubmit() {
  //   if (this.profileObj.password !== this.profileObj.cnfPassword) {
  //     await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.adminChangePassComp.onSubmit);
  //     return;
  //   }
  //   await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.adminChangePassComp.onSubmit);
  //   {
  //       const finalData = {
  //         userId: this.loggedInUserId,
  //         newPassword: this.profileObj.password,
  //       };
  //       this.showLoader = true;
  //       return this.http
  //         .post(
  //           this.appConfig.getLambdaUrl05() + ApiHelper.name_updateNewpassword,
  //           finalData,
  //           this.httpOptions
  //         )
  //         .subscribe(
  //           async (apiResponse: any) => {
  //             this.showLoader = false;
  //             await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.adminChangePassComp.onSubmit3);
  //             window.location.reload();
  //           },
  //           (err) => {
  //             // console.log(err);
  //           }
  //         );
      
  //   }
  // }
  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  checkPasswords(): void {
    // This will be triggered on every change in either the new password or confirm new password
    this.passwordMismatch = this.newPassword !== this.confirmNewPassword;
  }

async onSubmit() {
 
  const confirmation = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onSubmit
  );

  if (confirmation) {
      const finalData = {
          userId: this.loggedInUserId,
          newPassword: this.newPassword,
      };

      this.showLoader = true;
      
      this.http.post(
          this.appConfig.getLambdaUrl05() + ApiHelper.name_updateNewpassword,
          finalData,
          this.httpOptions
      )
      .subscribe(
        async (apiResponse) => {
          this.showLoader = false;
          await this.sweetAlertHelper.alertPopUp('Password Updated Successfully!');
          if (this.sweetAlertHelper.alertPopUp) {
            this.router.navigate(['admin/dashboard/' + this.loggedInUserId]);
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }
}


}
